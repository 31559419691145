export enum I18nNamespace {
  default = 'translation',
  localeDataset = 'locale-dataset',
}

export type I18nResource = {
  lng: string;
  ns: I18nNamespace;
  resources: { [key: string]: string };
};
