import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createWidgetPluginExports } from '@wix/members-area-widget-plugin-lib/viewer';

import {
  getControllerContext,
  initializeMyAccountWidget,
} from './controller-utils';

const createController: CreateControllerFn = async (controllerProps) => {
  const context = getControllerContext(controllerProps);

  const initialise = () => {
    return initializeMyAccountWidget(context);
  };

  return {
    async pageReady() {
      const isRendered = context.widgetPluginService.getIsRendered();

      if (!isRendered) {
        context.setProps({ isRendered });
        return Promise.resolve();
      }

      await initialise();
    },
    onBeforeUnLoad() {
      context.profileInfoChangeSubject?.unregisterObservers();
    },
    updateConfig(_, { publicData }) {
      context.settingsListener.notify(publicData.COMPONENT || {});
    },
    exports: () => {
      return createWidgetPluginExports(context.widgetPluginService, initialise);
    },
  };
};

export default createController;
