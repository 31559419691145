import { FlowApi } from '../../types';
import { SettingsEventHandler } from '../../types/appSettings';
import { Store } from '../../store';
import { registerSettingEventHandlers } from './controllerUtils/appSettingsEventHandlers';

export const registerControllerListeners = async (
  flowAPI: FlowApi,
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  const { isEditor } = flowAPI.environment;

  if (isEditor) {
    registerSettingEventHandlers(settingsEventHandler, store);
  }
};
