import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { State } from './slice';
import { Member, MemberListItem } from '../../../types';

export const setMember: CaseReducer<State, PayloadAction<Member>> = (
  state,
  action,
) => action.payload;

export const setSlug: CaseReducer<State, PayloadAction<string>> = (
  state,
  action,
) => ({
  ...state,
  slug: action.payload,
});

export const setBlockedMembers: CaseReducer<
  State,
  PayloadAction<MemberListItem[]>
> = (state, action) => {
  state.blockedMembers = action.payload;
};
