import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Field } from '../../../types';
import { accountDataThunk } from '../../thunks/accountData';

type State = Field[];

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState: [] as State,
  reducers: {
    setFields(state, action: PayloadAction<Field[]>) {
      state = action.payload;
    },
    setField(state, action: PayloadAction<Field>) {
      state = state.map((field) =>
        field.id === action.payload.id ? action.payload : field,
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(
      accountDataThunk.updateAccountData.fulfilled,
      (state, { payload }) => {
        if (!payload) {
          return;
        }
        const updatedFields = payload.fields;

        state.forEach((currentField) => {
          const updatedField = updatedFields.find(
            ({ id }) => id === currentField.id,
          );

          currentField.value = updatedField?.value ?? currentField.value;
          currentField.privacy = updatedField?.privacy ?? currentField.privacy;
        });
      },
    );
  },
});
