import { Member } from './member';
import { Field } from './fields';

export enum GroupTitles {
  BLOG_WRITER = 'Blog Writer',
}

export type AccountData = {
  member: Member;
  fields: Field[];
  site?: {
    isSSOMandatory: boolean;
    memberProfileURL: string;
    enabledSocialConnections: string[];
    isAboutInstalled: boolean;
    showPublicSections?: boolean;
  };
};

export type UpdateAccountDataRequest = {
  fields: Field[];
  member: Member;
  changedPrivacyFieldsIds: Field['id'][];
};

export enum ServerErrorCode {
  UNAUTHENTICATED,
  PERMISSION_DENIED,
  UNAVAILABLE,
  INTERNAL,
  INVALID_ARGUMENT,
  INVALID_CUSTOM_FIELD_URL,
}
